import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { up } from 'styled-breakpoints';

import Post, {
  ShareButtonContainer,
  MoreButton,
  Container as PostContainer,
  ContainerOuter,
} from '../components/post/Post';

import { Container as InfoContainer } from '../components/post/Info';

import { useAllPosts } from '../hooks/useAllPosts';
import { NavigationContainer } from '../components/Navigation';

import generateSlug from '../utils/generateSlug';

import CourierNewWoff from '../styles/fonts/CourierNew.woff';
import CourierNewWoff2 from '../styles/fonts/CourierNew.woff2';

import Ashes_0 from '../images/ashes/Ashes_0.jpg';
import Ashes_1 from '../images/ashes/Ashes_1.jpg';
import Ashes_2 from '../images/ashes/Ashes_2.jpg';
import Ashes_3 from '../images/ashes/Ashes_3.jpg';
import Ashes_4 from '../images/ashes/Ashes_4.jpg';
import Ashes_5 from '../images/ashes/Ashes_5.jpg';
import Ashes_6 from '../images/ashes/Ashes_6.jpg';
import Ashes_7 from '../images/ashes/Ashes_7.jpg';
import Ashes_8 from '../images/ashes/Ashes_8.jpg';
import Ashes_9 from '../images/ashes/Ashes_9.jpg';
import Ashes_10 from '../images/ashes/Ashes_10.jpg';
import Ashes_11 from '../images/ashes/Ashes_11.jpg';
import Ashes_12 from '../images/ashes/Ashes_12.jpg';
import Ashes_13 from '../images/ashes/Ashes_13.jpg';
import Ashes_14 from '../images/ashes/Ashes_14.jpg';
import Ashes_15 from '../images/ashes/Ashes_15.jpg';
import Ashes_16 from '../images/ashes/Ashes_16.jpg';
import Ashes_17 from '../images/ashes/Ashes_17.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  transform: scale(2);
  transform-origin: 0 0;

  > div {
    &:nth-child(18n + 1) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_0});
        }
      }
    }

    &:nth-child(18n + 2) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_1});
        }
      }
    }

    &:nth-child(18n + 3) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_2});
        }
      }
    }

    &:nth-child(18n + 4) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_3});
        }
      }
    }

    &:nth-child(18n + 5) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_4});
        }
      }
    }

    &:nth-child(18n + 6) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_5});
        }
      }
    }

    &:nth-child(18n + 7) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_6});
        }
      }
    }

    &:nth-child(18n + 8) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_7});
        }
      }
    }

    &:nth-child(18n + 9) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_8});
        }
      }
    }

    &:nth-child(18n + 10) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_9});
        }
      }
    }

    &:nth-child(18n + 11) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_10});
        }
      }
    }

    &:nth-child(18n + 12) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_11});
        }
      }
    }

    &:nth-child(18n + 13) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_12});
        }
      }
    }

    &:nth-child(18n + 14) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_13});
        }
      }
    }

    &:nth-child(18n + 15) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_14});
        }
      }
    }

    &:nth-child(18n + 16) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_15});
        }
      }
    }

    &:nth-child(18n + 17) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_16});
        }
      }
    }

    &:nth-child(18n + 18) {
      ${ContainerOuter} {
        &::before {
          background-image: url(${Ashes_17});
        }
      }
    }
  }
`;

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Courier New';
    src:  url(${CourierNewWoff2}) format('woff2'),      
          url(${CourierNewWoff}) format('woff');      
    font-weight: normal;
  }

  ${ShareButtonContainer},
  ${MoreButton},
  ${NavigationContainer},
  ${InfoContainer} {
    display: none;
  }

  ${ContainerOuter} {
    margin-bottom: 0;
    padding-bottom: 0;
    position: relative;
    

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
    }
  }

  ${PostContainer} {
    width: 780px;
    background-color: transparent;
  }

  ${Container} {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
`;

const OgImages = () => {
  const allPosts = useAllPosts();

  return (
    <Container>
      <GlobalStyles />
      {allPosts.map((post) => (
        <div className={`og-image-${generateSlug(post)}`} key={post.originalId}>
          <Post rightHandSide={false} {...post} />
        </div>
      ))}
    </Container>
  );
};

export default OgImages;
